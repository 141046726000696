/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const APP_NAME = "Gility";
export const APP_PAYOFF = "La formazione per il futuro";
export const APP_HOME_PAYOFF = "La formazione per il futuro";
export const APP_DESCRIPTION =
  "Il nuovo aggregatore di corsi di formazione online, capace di raccogliere le proposte più interessanti ed efficaci per le PMI come la tua.";
export const BLOG_NAME = "Gility Mag";
export const COMPANY_FULLNAME = "Gility S.r.l. Società Benefit";

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN ?? false;

const _VERCEL_URL = process.env.VERCEL_URL ?? process.env.NEXT_PUBLIC_VERCEL_URL;
const VERCEL_URL = _VERCEL_URL ? `https://${_VERCEL_URL}` : undefined;
export const LEARN_API_KEY = process.env.LEARN_API_KEY;

export const WWW_APP_URL =
  process.env.NEXT_PUBLIC_WWW_APP_URL ?? VERCEL_URL ?? "http://localhost:3000";

export const LEARN_APP_URL =
  process.env.NEXT_PUBLIC_LEARN_APP_URL ?? VERCEL_URL ?? "http://localhost:3001";

export const LEGACY_APP_URL = process.env.NEXT_PUBLIC_LEGACY_APP_URL ?? "http://localhost:4000";
export const ADMIN_APP_URL = process.env.NEXT_PUBLIC_ADMIN_APP_URL ?? "http://localhost:4001";

export const GILITY_ENV = process.env.NEXT_PUBLIC_GILITY_ENV ?? "development";

// testing
export const IS_E2E_MODE = process.env.NEXT_PUBLIC_IS_E2E_MODE === "1" || false;

export const FONDIMPRESA_API_URL = process.env.FONDIMPRESA_API_URL ?? "http://localhost:3002";
export const FONDIMPRESA_API_TOKEN = process.env.FONDIMPRESA_API_TOKEN;
export const FONDIMPRESA_ENCRYPTION_KEY = process.env.FONDIMPRESA_ENCRYPTION_KEY ?? "secure";
export const FONDIMPRESA_FETCH_INTERVAL_DAYS = process.env.FONDIMPRESA_FETCH_INTERVAL_DAYS
  ? parseInt(process.env.FONDIMPRESA_FETCH_INTERVAL_DAYS, 10)
  : 7;

export const SEO_IMG_DEFAULT = `${WWW_APP_URL}/og-image.jpg`;
// The Dynamic OG Image is passed through Next's Image API to further optimize it.
// This results in a 80% smaller image.
export const SEO_IMG_OGIMG = `${WWW_APP_URL}/_next/image?w=1200&q=100&url=${encodeURIComponent(
  `/api/og-image`,
)}`;

export const LOGO = "/gility-logo-white-word.svg";

export const GCS_BUCKET_NAME = process.env.GCS_BUCKET_NAME ?? "gility-dev";

export const GC_TASKS_SACC_EMAIL = process.env.GC_TASKS_SACC_EMAIL;
export const GC_TASKS_SACC_PKEY = process.env.GC_TASKS_SACC_PKEY?.split(String.raw`\n`).join("\n");

export const GCP_GILITY_PROJECT = process.env.GCP_GILITY_PROJECT ?? "genial-analyzer-369014";
export const GCP_GILITY_REGION = process.env.GCP_GILITY_REGION ?? "europe-west3";

export const JWT_MAX_AGE = 1 * 24 * 30 * 60; // 15 days
export const MAX_RESET_PASSWORD_TOKEN_AGE = 60 * 60 * 24 * 7; // 7 days

export const HUBSPOT_PRIVATE_APP_TOKEN = process.env.HUBSPOT_PRIVATE_APP_TOKEN;
export const HUBSPOT_MARKETING_LIST_ID = process.env.HUBSPOT_MARKETING_LIST_ID;
export const HUBSPOT_SUBSCRIPTION_ID = process.env.HUBSPOT_SUBSCRIPTION_ID;

export const INNGEST_EVENT_KEY = process.env.INNGEST_EVENT_KEY;
export const INNGEST_SIGNING_KEY = process.env.INNGEST_SIGNING_KEY;

// Emails
export const EMAILS_DEFAULT_FROM = process.env.DEFAULT_EMAIL_FROM ?? "Gility <noreply@gility.it>";
export const EMAILS_SENDGRID_API_KEY = process.env.EMAILS_SENDGRID_API_KEY ?? "";
export const EMAILS_PROVIDER = process.env.EMAILS_PROVIDER ?? "sendgrid";
export const EMAILS_GILITY_LIVE_EMAIL =
  process.env.EMAILS_GILITY_LIVE_EMAIL ?? "formazione@gility.it";
export const EMAILS_GILITY_SUPPORT_EMAIL =
  process.env.EMAILS_GILITY_SUPPORT_EMAIL ?? "assistenza@gility.it";

export const GENERIC_ERROR_MESSAGE = `Si è verificato un errore. Riprova più tardi oppure scrivici ad ${EMAILS_GILITY_SUPPORT_EMAIL}`;

export const DAILY_API_KEY = process.env.DAILY_API_KEY;
export const GOOGLE_SERVICE_KEY = process.env.GOOGLE_SERVICE_KEY ?? "e30=";
export const TYPEFORM_TOKEN = process.env.TYPEFORM_TOKEN;
export const TYPEFORM_TEMPLATE_LIVE_ID = process.env.TYPEFORM_TEMPLATE_LIVE_ID;
export const TYPEFORM_TEMPLATE_ONDEMAND_ID = process.env.TYPEFORM_TEMPLATE_ONDEMAND_ID;
export const TYPEFORM_LINK = process.env.NEXT_PUBLIC_TYPEFORM_LINK;
export const TYPEFORM_WORKSPACE_ID = process.env.TYPEFORM_WORKSPACE_ID;

export const LIVE_SHORT_INTERVALS = process.env.NEXT_PUBLIC_LIVE_SHORT_INTERVALS == "1";

// Algolia
export const ALGOLIA_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID!;
export const ALGOLIA_SEARCH_KEY = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY!;
export const ALGOLIA_ADMIN_KEY = process.env.ALGOLIA_ADMIN_KEY;

// Google Tag Manager
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

// Didomi
export const DIDOMI_SDK_KEY = process.env.NEXT_PUBLIC_DIDOMI_SDK_KEY;

// Slack
export const SLACK_TYPEFORM_WEBHOOK_URL = process.env.SLACK_TYPEFORM_WEBHOOK_URL;
export const SLACK_ORDER_WEBHOOK_URL = process.env.SLACK_ORDER_WEBHOOK_URL;
export const SLACK_CERTIFICATES_NOTIFICATION_URL = process.env.SLACK_CERTIFICATES_NOTIFICATION_URL;
export const SLACK_HELP_WEBHOOK_URL = process.env.SLACK_HELP_WEBHOOK_URL;

// Docebo
export const DOCEBO_API_ENABLED = process.env.DOCEBO_API_ENABLED === "1";
export const DOCEBO_FLOW_ENABLED = process.env.NEXT_PUBLIC_DOCEBO_FLOW_ENABLED === "1";
export const DOCEBO_BRANCH_ID = process.env.DOCEBO_BRANCH_ID!;

// Database
export const DATABASE_DEBUG = process.env.DATABASE_DEBUG === "1";
export const DATABASE_DEBUG_LITE = process.env.DATABASE_DEBUG_LITE === "1";
export const DATABASE_THROTTLE_MS = parseInt(process.env.DATABASE_THROTTLE_MS ?? "0");

// Stripe
export const STRIPE_SECRET_KEY = process.env.STRIPE_SECRET_KEY!;

// Paypal
export const PAYPAL_API_URL = process.env.PAYPAL_API_URL;
export const PAYPAL_CLIENT_SECRET = process.env.PAYPAL_CLIENT_SECRET;
export const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID;
export const PAYPAL_TEST_EMAIL = process.env.PAYPAL_TEST_EMAIL;
export const PAYPAL_TEST_PASSWORD = process.env.PAYPAL_TEST_PASSWORD;

// AWS
export const AWS_ACCESS_KEY_ID = process.env.AWS_ACCESS_KEY_ID!;
export const AWS_ACCESS_KEY_SECRET = process.env.AWS_ACCESS_KEY_SECRET!;
export const AWS_REGION = process.env.AWS_REGION!;
export const AWS_S3_BUCKET_NAME = process.env.AWS_S3_BUCKET_NAME ?? "gility-dev";

// Company Courses
export const COMPANY_COURSES_FILES_BUCKET_BASEPATH =
  process.env.NEXT_PUBLIC_COMPANY_COURSES_FILES_BUCKET_BASEPATH ?? "company-course-files";

// PostHog
export const POSTHOG_API_KEY = process.env.NEXT_PUBLIC_POSTHOG_API_KEY;
